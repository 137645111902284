<template>
  <div class="pa-6 ma-4">
    <p class="text-2xl mb-6">
      CA journalier par site
      <dag-info
        :dags="[
          'ga_get_and_store_site_daily_dag',
          'partner_get_and_store_daily_dag',
          'partner_get_and_store_daily_ifempty_dag',
        ]"
      />
    </p>

    <v-expansion-panels class="mb-2" id="expandableFilters">
      <v-expansion-panel>
        <v-expansion-panel-header> </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="ml-3">
            <v-col cols="6" sm="4" md="3">
              <partner-group-filter store="premium" />
            </v-col>
            <v-col cols="6" sm="4" md="6">
              <partner-view-filter store="premium" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card class="mb-0 pt-2">
      <v-row class="mt-3 mb-0 d-flex">
        <v-col cols="3" class="mb-0 pb-0">
          <!-- <date-filter
                  store="premium"
                  storeGetter="getMonthDate"
                  storeUpdater="updateMonthDate"
                  format="month"
                  :isRange="false"
                /> -->
          <date-range-filter
            @valueChanged="setDates"
            @frequencyChanged="setFrequency"
            periodType="past-and-today"
            format="date"
            :frequencies="['daily', 'weekly', 'monthly']"
            :frequency="this.frequency"
            range="CURRENT_MONTH"
            :onCreatedEmitDates="true"
            :outlined="true"
            :allowNoLimit="false"
            wrapperClass="date-range-filter"
            :showOpenDialogBtn="false"
            class="mb-0 pb-0"
          ></date-range-filter
          ><!--:showBtnList="['CURRENT_MONTH']"-->
        </v-col>
        <v-col cols="3">
          <site-group-filter store="premium" :dense="true" />
        </v-col>
        <v-col cols="4">
          <site-filter store="premium" :dense="true" />
        </v-col>
        <v-col cols="1">
          <v-btn @click="confirmApplyFilters" outlined color="primary">
            APPLIQUER
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row v-show="componentKey > 0">
      <v-col cols="12">
        <ca-and-audience-by-site-separated-chart
          v-if="sites.length > 0 && dates.length === 2"
          :dates="confirmedFilters.dates"
          :frequency="confirmedFilters.frequency"
          :partnerViews="confirmedFilters.partnerViews"
          :sites="confirmedFilters.sites"
          :key="componentKey"
          :componentKey="componentKey"
        ></ca-and-audience-by-site-separated-chart>
        <!--
          :params="applyedFilters" 
          :dates="dates"
          :partner-views="partnerViews"
          :sites="sites"
          -->
        <div v-else>Veuillez sélectionner un ou plusieurs sites</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from "@/store";

import DagInfo from "@/components/common/menus/DagInfo";
import PartnerGroupFilter from "@/components/common/filters/PartnerViewGroupFilter";
import PartnerViewFilter from "@/components/common/filters/PartnerViewFilter.vue";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateRangeFilter from "@/components/common/filters/DateRangeFilter.vue";
import CaAndAudienceBySiteSeparatedChart from "@/components/premium/ca-by-site/cards/CaAndAudienceBySiteSeparatedChart";
import { getDateStr, daysNumberBetween } from "@/utils/dates";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "CaAndAudienceBySite",
  components: {
    ApplyFilters,
    CaAndAudienceBySiteSeparatedChart,
    DagInfo,
    DateRangeFilter,
    PartnerGroupFilter,
    PartnerViewFilter,
    SiteFilter,
    SiteGroupFilter,
  },
  data() {
    return {
      confirmedFilters: {
        monthDate: null,
        dates: this.dates,
        frequency: this.frequency,
        sites: this.sites,
        partnerViews: this.partnerViews,
      },
      dailyWarningTreshold: 180,
      componentKey: 0,
      usePrettyNumbers: true,
      loading: false,
      frequency: "daily",
      dates: [],
    };
  },
  mounted() {
    saveRmraView(this.$route);
  },
  async created() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "fullWidth");
    // this.sites = [2, 126]; // uncomment for tests and sites in data and comment sites() in computed
    if (
      !this.$route?.query?.partnerViews &&
      !this.$route?.query?.partnerViewGroups
    ) {
      this.resetFilters();
    }

    if (this.$route.query.siteGroups) {
      const siteGroups = this.$route.query.siteGroups.split(",");
      await this.$store.dispatch("premium/updateSiteGroups", siteGroups);
    }
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "boxed");
  },
  methods: {
    async confirmApplyFilters() {
      // check  sites
      if (this.sites.length < 1) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Veuillez sélectionner au moins un site.`,
          color: "warning",
          timeout: 2000,
        });
        return;
      }
      // check frequency and number of days
      if (this.dates.length !== 2) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Veuillez sélectionner une pèriode entre 2 dates.`,
          color: "warning",
          timeout: 2000,
        });
        return;
      }
      const daysNumber = daysNumberBetween(this.dates[0], this.dates[1]);
      if (this.frequency == "daily" && daysNumber > this.dailyWarningTreshold) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `La pèriode sélectionnée excède ${this.dailyWarningTreshold} jours alors  
            la périodicité passe automatiquement de "daily" à "weekly" afin d'assurer un affichage correct du graphique.`,
          color: "info",
          timeout: 5000,
        });
        console.warn(
          `in daily ${daysNumber} jours > dailyWarningTreshold ${this.dailyWarningTreshold} then changed to "weekly"`
        );
        this.frequency = "weekly";
        this.applyFilters();
        return;
      }
      // await store.dispatch("app/setDialog", {
      //   show: true,
      //   type: "confirm",
      //   width: 800,
      //   title: "Appliquer",
      //   text: `Vous souhaitez afficher ${daysNumber} jours en pèriodicité "daily".
      //         Cela risque d'être très long.
      //         Il est préférable d'annuler et sélectionner une périodicité "Weekly" ou "monthly".
      //         ou bien un intervalle inférieur à ${this.dailyWarningTreshold} jours.`,
      //   code: null,
      //   callbackFunction: this.applyFilters,
      //   //callbackFunction: () => { this.applyFilters(); },
      // });
      this.applyFilters();
    },
    applyFilters() {
      setTimeout(() => {
        // bug if not in settimeout ...
        this.confirmedFilters = {
          sites: this.sites,
          dates: this.dates,
          frequency: this.frequency,
          partnerViews: this.partnerViews,
        };
        this.componentKey += 1;
      }, 100);
    },
    async resetFilters() {
      // let monthDate = new Intl.DateTimeFormat("fr-FR")
      //   .format(new Date())
      //   .split("/");
      // this.$store.dispatch(
      //   "premium/updateMonthDate",
      //   `${monthDate[2]}-${monthDate[1]}`
      // );
      const partnerViewGroups = await this.$store.dispatch(
        "common/getPartnerViewGroupList"
      );
      const exportSuiviGeneral = partnerViewGroups.find(
        (g) => g.name === "Export Suivi Général"
      );

      this.$store.dispatch(
        "premium/updatePartnerViewGroups",
        exportSuiviGeneral ? [exportSuiviGeneral.id] : [] //
      );
    },
    getDateStr(date) {
      return getDateStr(date);
    },
    setDates(dates) {
      this.dates = dates;
    },
    prettyNbs(x, sep = " ") {
      if (typeof x == "undefined") return x;
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    },
    setFrequency(frequencyObj) {
      this.frequency = frequencyObj.name;
    },
  },
  computed: {
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    partnerViews() {
      return this.$store.getters["premium/getPartnerViews"];
    },
    partnerViewList() {
      return this.$store.getters["common/getPartnerViewList"];
    },
  },
};
</script>
<style>
.v-input__control {
  padding-bottom: 0 !important;
}
</style>
